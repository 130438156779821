<template>
  <div class="account-address-book">
    <h1 v-if="editAddressId > 0">{{ $t("edit_address") }}</h1>
    <h1 v-if="editAddressId == -1">{{ $t("new_address") }}</h1>
    <div class="address-block">
      <b-row>
        <b-col md="6">
          <h2>{{ $t("contact_information") }}</h2>

          <b-form-group class="account-inputs" id="prefix-group" :label="$t('form_prefix')" label-for="prefix">
            <b-form-select id="prefix" v-model="form.prefix" :state="form.prefixState" class="form-select"
              :options="prefixOptions" v-on:change="validatePrefix" aria-describedby="prefix-feedback">
            </b-form-select>
            <b-form-invalid-feedback id="prefix-feedback" v-if="form.prefixError">
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            class="account-inputs"
            id="first-name-group"
            :label="$t('form_first_name')"
            label-for="first_name"
          >
            <b-form-input
              v-validParent
              id="first_name"
              v-model="form.firstname"
              type="text"
              v-on:blur="validateFirstname()"  @input="validateFirstname()"
              :state="form.firstnameState"
              aria-describedby="firstname-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="firstname-feedback">
              {{ form.firstnameError }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            class="account-inputs"
            id="last-name-group"
            :label="$t('form_last_name')"
            label-for="last_name"
          >
            <b-form-input
              v-validParent
              id="last_name"
              v-model="form.lastname"
              type="text"
              v-on:blur="validateLastname()"  @input="validateLastname()"
              :state="form.lastnameState"
              aria-describedby="lastname-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="lastname-feedback">
              {{ form.lastnameError }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-checkbox
            id="checkbox-1"
            v-model="isCompany"
            name="checkbox-1"
            >{{ $t("form_are_you_a_company") }}</b-form-checkbox
          >
          <div v-if="isCompany">
            <b-form-group
              class="account-inputs"
              id="company-group"
              :label="$t('form_company_name')"
              label-for="company"
            >
              <b-form-input
                v-validParent
                id="company"
                v-model="form.company"
                type="text"
                v-on:blur="validateCompany()"  @input="validateCompany()"
                :state="form.companyState"
                aria-describedby="company-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="company-feedback">
                {{ form.companyError }}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              class="account-inputs"
              id="taxvat-group"
              :label="$t('form_vat_number')"
              label-for="taxvat"
            >
              <b-form-input
                v-validParent
                id="taxvat"
                v-model="form.taxvat"
                type="text"
                :state="form.taxvatState"
                aria-describedby="taxvat-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="taxvat-feedback">
                {{ form.taxvatError }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>

        </b-col>
        <b-col md="6">
          <h2>{{ $t("address_title") }}</h2>
          <b-form-group
            class="account-inputs"
            id="country-group"
            :label="$t('form_country')"
            label-for="country"
          >
            <b-form-select
              id="country"
              v-model="form.address.country_code"
              :options="countries"
              :state="form.address.country_codeState"
              v-on:change="validateCountry"
              class="form-select"
            ></b-form-select>
          </b-form-group>

          <b-form-group
            v-if="!showFullAddress"
            class="account-inputs"
            id="postcode-group1"
            :label="$t('form_postal_code')"
            label-for="postcode1"
          >
            <b-form-input
              v-validParent
              id="postcode1"
              v-model="form.address.postcode"
              type="text"
              v-on:blur="validatePostCode()"  @update="validatePostCode()"
              :state="form.address.postcodeState"
              aria-describedby="address.postcode1-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="address.postcode1-feedback">
              {{ form.address.postcodeError }}
            </b-form-invalid-feedback>
          </b-form-group>
          
          <b-form-group
            v-if="showFullAddress"
            class="account-inputs"
            id="street-group"
            :label="$t('form_street')"
            label-for="street"
          >
            <b-form-input
              v-validParent
              id="street"
              v-model="form.address.streetDisplay"
              type="text"
              v-on:blur="validateStreet()"  @input="validateStreet()"
              :state="form.address.streetDisplayState"
              aria-describedby="address.streetDisplay-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="address.streetDisplay-feedback">
              {{ form.address.streetDisplayError }}
            </b-form-invalid-feedback>
          </b-form-group>


          <!-- <b-form-group class="account-inputs" id="house_number-group" :label="$t('form_house_number_and_addition')"
                label-for="form_house_number_and_addition">
                <b-form-input
                  v-validParent :placeholder="$t('form_house_number_and_addition')" id="house_number_and_addition"
                  v-model="form.address.house_number_and_addition" type="text"
                  v-on:blur="validateHouseNumberAndAddition()" v-on:input="validateHouseNumberAndAddition()" :state="form.address.house_number_and_additionState"
                  aria-describedby="address.house_number_and_addition-feedback"></b-form-input>
                <b-form-invalid-feedback id="address.house_number_and_addition-feedback">
                  {{ form.address.house_number_and_additionError }}
                </b-form-invalid-feedback>
          </b-form-group> -->

          <b-row>
            <b-col sm="6" class="first">
              <b-form-group
                class="account-inputs"
                id="house_number-group"
                :label="$t('form_house_number') + '*'"
                label-for="house_number"
              >
                <b-form-input
                  v-validParent
                  :placeholder="$t('form_house_number')"
                  id="house_number"
                  v-model="form.address.house_number"
                  type="text"
                  v-on:blur="validateHouseNumber()"
                  :state="form.address.house_numberState"
                  aria-describedby="address.house_number-feedback"
                ></b-form-input>
                <b-form-invalid-feedback id="address.house_number-feedback">
                  {{ form.address.house_numberError }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col sm="6" class="third">
              <b-form-group
                class="account-inputs"
                id="addition-group"
                :label="$t('form_addition')"
                label-for="addition"
              >
                <b-form-input
                  id="addition"
                  v-model="form.address.addition"
                  :state="form.address.additionState"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-form-group v-if="showFullAddress" class="account-inputs" id="postcode-group1"
                :label="$t('form_postal_code')" label-for="postcode1">
                <b-form-input
                  v-validParent :placeholder="$t('form_postal_code')" id="postcode1" v-model="form.address.postcode"
                  type="text" v-on:input="validatePostCode()" :state="form.address.postcodeState"
                  aria-describedby="address.postcode1-feedback"></b-form-input>
                <b-form-invalid-feedback id="address.postcode1-feedback">
                  {{ form.address.postcodeError }}
                </b-form-invalid-feedback>
              </b-form-group>

          <b-form-group
            v-if="showFullAddress"
            class="account-inputs"
            id="city-group"
            :label="$t('form_city')"
            label-for="city"
          >
            <b-form-input
              v-validParent
              id="city"
              v-model="form.address.city"
              type="text"
              v-on:blur="validateCity()"  @input="validateCity()"
              :state="form.address.cityState"
              aria-describedby="address.city-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="address.city-feedback">
              {{ form.address.cityError }}
            </b-form-invalid-feedback>
          </b-form-group>


          <b-form-group
            v-if="foundAddress != '' && showFullAddress == false"
            class="account-inputs"
            id="add-group"
            label=" "
            label-for="add"
          >
            <div id="add">
              {{ foundAddress }}
            </div>
          </b-form-group>

          <b-form-group
            v-if="foundAddresses.length > 0 && showFullAddress == false"
            class="account-inputs"
            id="addm-group"
            label=" "
            label-for="addm"
          >
            <div id="addm">
              <b-form-radio
                class="account-radios label-fw-100"
                v-model="selectedAddress"
                v-for="(item, index) in foundAddresses"
                :key="index"
                name="some-radios"
                :value="index"
                >{{ item.text }}</b-form-radio
              >
            </div>
          </b-form-group>

          <b-form-checkbox v-if="showManual" id="manual" v-model="showFullAddress" name="manual">
            {{ $t("form_fillout_address_manual") }}
          </b-form-checkbox>

          <b-form-group
            class="account-inputs"
            id="telephone-group"
            :label="$t('form_phone')"
            label-for="telephone"
          >
            <b-form-input
              v-validParent
              id="telephone"
              v-model="form.address.telephone"
              type="text"
              v-on:blur="validatePhone()"  @input="validatePhone()"
              :state="form.address.telephoneState"
              aria-describedby="address.telephone-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="address.telephone-feedback">
              {{ form.address.telephoneError }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-checkbox
            id="default_billing"
            v-model="form.address.default_billing"
            name="default_billing"
            >{{ $t("form_default_billing_edit_address") }}</b-form-checkbox
          >

          <b-form-checkbox
            id="default_shipping"
            v-model="form.address.default_shipping"
            name="default_shipping"
            >{{ $t("form_default_shipping_edit_address") }}</b-form-checkbox
          >
        </b-col>
      </b-row>
    </div>
    <div class="form-footer">
      <b-button @click="cancel" class="form-submit-btn">
        <strong>
        {{
          $t("cancel")
        }}
        </strong>
      </b-button>
      &nbsp;
      <b-button
        @click="submit"
        variant="info"
        class="text-white form-submit-btn"
        >{{ $t("save") }}</b-button
      >
    </div>
  </div>
</template>
<script>
import config from "@config";
import {  validateVatNumber } from "@storefront/core/data-resolver/user";
import Address from "@/esf_kerkrade_vitanatura/store/mixins/address";

export default {
  name: "AccountEditAddress",
  mixins: [Address],
  computed: {
    tabIndex: {
      get() {
        return this.$store.getters["user/getAccountTabIndex"];
      },
      set(val) {
        this.$store.commit("user/setAccountTabIndex", val);
      },
    },
    editAddressId: {
      get() {
        return this.$store.getters["user/getEditAddressId"];
      },
      set(val) {
        this.$store.commit("user/setEditAddressId", val);
      },
    },
    user() {
      return this.$store.getters["user/getCurrentUser"];
    },
    countries() {
      return this.$store.getters["user/getCountries"];
    },
    country() {
      return this.form.address.country_code;
    },
    postcodeHouseNumber() {
      return `${this.form.address.postcode}|${this.form.address.house_number}|${this.form.address.addition}`;
    },
    vatNumber() {
      return this.form.taxvat;
    },
  },
  mounted() {
    if (this.editAddressId != null && this.editAddressId != -1) {
      const address = this.$store.getters["user/getAddressByID"](
        this.editAddressId
      );
      if (address?.company != null) {
        this.isCompany = true;
      } else {
        this.isCompany = false;
      }
      this.form.company = address?.company;
      this.form.address.country_code = address?.country_code;
      this.form.prefix = address?.prefix  == null ? "" : address?.prefix;
      this.form.firstname = address?.firstname;
      this.form.lastname = address?.lastname;
      this.form.address.streetDisplay = address?.street[0];
      this.form.address.house_number = address?.street[1];
      this.form.address.addition = address?.street[2];
      this.form.address.postcode = address?.postcode;
      this.form.address.city = address?.city;
      this.form.address.telephone = address?.telephone;
      this.form.address.default_billing = address?.default_billing;
      this.form.address.default_shipping = address?.default_shipping;
      if (address?.vat_id != null) {
        this.form.taxvat = address?.vat_id;
      } else {
        this.form.taxvat = "";
      }
      this.validateAddressMethod();
    } else {
      if (typeof config.customers.defaultCountry != "undefined") {
        this.form.address.country_code = config.customers.defaultCountry;
        this.validateCountry()
      }
    }
    if (config.postcode_validation.enabled == false) {
      this.manual = true;
      this.showManual = false;
    }
  },
  data() {
    return {
      isCompany: true,
      thanksActive: false,
      showFullAddress: true,
      showManual: true,
      typeTimer: null,
      foundAddresses: [],
      foundAddress: "",
      selectedAddress: null,
      isValidVatNumber: false,
      showVatMessage: "",
      showVatCheck: false,
      prefixOptions: [
        { value: this.$t('male') , text: this.$t('male') },
        { value: this.$t('female'), text: this.$t('female') },
        { value: '', text: this.$t('not_specified') },
      ],
      form: {
        company: "",
        companyState: null,
        companyError: "",
        companyTimer: null,
        prefix: "",
        prefixState: null,
        prefixError: "",
        firstname: "",
        firstnameState: null,
        firstnameError: "",
        firstnameTimer: null,
        lastname: "",
        lastnameState: null,
        lastnameError: "",
        lastnameTimer: null,
        taxvat: "",
        taxvatState: null,
        taxvatError: "",
        taxvatTimer: null,

        address: {
          id: null,
          country_code: "",
          postcode: "",
          postcodeState: null,
          postcodeError: "",
          postcodeTimer: null,

          street: "",
          streetDisplay: "",
          streetDisplayState: null,
          streetDisplayError: "",
          streetDisplayTimer: null,

          house_number: "",
          house_numberState: null,
          house_numberError: "",
          house_numberTimer: null,

          house_number_and_addition: "",
          house_number_and_additionState: null,
          house_number_and_additionError: "",
          house_number_and_additionTimer: null,

          addition: "",
          additionState: null,
          city: "",
          cityState: null,
          cityError: "",
          cityTimer: null,

          telephone: "",
          telephoneState: null,
          telephoneError: "",
          telephoneTimer: null,
          default_billing: true,
          default_shipping: true,
        },
        checkbox2: false,
        manual: true,
      },
    };
  },
  methods: {
    validateAddressMethod(){
      if (this.isCompany == true) {
        if (this.form.companyState != true) {
          this.validateCompany();
        }
        if (this.form.taxvatState != true) {
          this.validateVAT();
        }
      }
      if (this.form.prefixState != true) {
        this.validatePrefix();
      }
      if (this.form.firstnameState != true) {
        this.validateFirstname();
      }
      if (this.form.lastnameState != true) {
        this.validateLastname();
      }
      if (this.form.address.postcodeState != true) {
        this.validatePostCode();
      }
      if (this.form.address.house_numberState != true) {
        this.validateHouseNumber();
      }
      if (this.form.address.streetDisplayState != true) {
        this.validateStreet();
        
      }
      if (this.form.address.telephoneState != true) {
        this.validatePhone();
      }
      if (this.form.address.cityState != true) {
        this.validateCity();
      }
    },
    async submit() {
      let formValid = true;
      if (this.isCompany == true) {
        if (this.form.companyState != true) {
          this.validateCompany();
          if (this.form.companyState != true) {
            formValid = false;
          }
        }
        if (this.form.taxvatState != true) {
          this.validateVAT();
          if (this.form.companyState != true || this.form.taxvatState != true) {
            formValid = false;
          }
        }
      }
      if (this.form.prefixState != true) {
        this.validatePrefix();
        if (this.form.prefixState != true) {
          formValid = false;
        }
      }
      if (this.form.firstnameState != true) {
        this.validateFirstname();
        if (this.form.firstnameState != true) {
          formValid = false;
        }
      }
      if (this.form.lastnameState != true) {
        this.validateLastname();
        if (this.form.lastnameState != true) {
          formValid = false;
        }
      }
      if (this.form.address.postcodeState != true) {
        this.validatePostcode();
        if (this.form.address.postcodeState != true) {
          formValid = false;
        }
      }
      if (this.form.address.house_numberState != true) {
          formValid = false;
      }
      if (this.form.address.streetDisplayState != true) {
        this.validateStreet();
        if (this.form.address.streetDisplayState != true) {
          formValid = false;
          if (!config.postcode_validation.countries.includes(this.country)) {
            this.showFullAddress = true;
          }
        }
      }
      if (this.form.address.telephoneState != true) {
        this.validatePhone();
        if (this.form.address.telephoneState != true) {
          formValid = false;
        }
      }
      if (this.form.address.cityState != true) {
        this.validateCity();
        if (this.form.address.cityState != true) {
          formValid = false;
        }
      }
      if (formValid == true) {
        const address = {};
        if (this.editAddressId > 0) {
          address.id = this.editAddressId;
        }
        address.prefix = this.form.prefix
        address.company = this.form.company;
        address.country_code = this.form.address.country_code;
        address.firstname = this.form.firstname;
        address.lastname = this.form.lastname;
        address.street = `${this.form.address.streetDisplay}\n${this.form.address.house_number}`;
        address.postcode = this.form.address.postcode;
        address.city = this.form.address.city;
        address.telephone = this.form.address.telephone;
        address.vat_id = this.form.taxvat;
        address.default_billing = this.form.address.default_billing;
        address.default_shipping = this.form.address.default_shipping;
        if (this.isCompany != true) {
          address.company = "";
          address.vat_id = "";
        }
        if (this.editAddressId > 0) {
          const retval = await this.$store.dispatch("user/updateAddress", {
            address: address,
          });
          if (retval == true) {
            const msg = {
              type: "success",
              title: this.$t("account"),
              text: this.$t("address_modified"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
            this.editAddressId = 0;
          }
        } else if (this.editAddressId == -1) {
          const retval = await this.$store.dispatch("user/addAddress", {
            address: address,
          });
          if (retval == true) {
            const msg = {
              type: "success",
              title: this.$t("account"),
              text: this.$t("address_modified"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
            this.editAddressId = 0;
          }
        }
      }
    },
    cancel() {
      this.editAddressId = 0;
    },
    async validateVAT() {
      if (this.isCompany == true) {
        if (this.form.taxvat != "") {
          if (
            this.form.taxvat.substring(0, 2).toUpperCase() ===
            this.form.address.country_code
          ) {
            const retval = await validateVatNumber(this.form.taxvat);
            if (retval.is_valid == true) {
              this.isValidVatNumber = true;
              this.form.taxvatState = true;
            } else {
              this.isValidVatNumber = false;
              this.form.taxvatState = false;
              this.form.taxvatError = this.$t("taxvat_invalid");

              this.showVatMessage = this.$t("taxvat_invalid");
            }
          } else {
            this.isValidVatNumber = false;
            this.form.taxvatState = false;
            this.form.taxvatError = this.$t("taxvat_wrong_country");
          }
        } else {
          this.form.taxvatState = null;
          this.form.taxvatError = "";
        }
      } else {
        this.form.taxvatState = null;
      }
    },
    validateCompany() {
      if (this.isCompany == true) {
        if (this.form.company == "" || this.form.company == null) {
          this.form.companyState = false;
          this.form.companyError = this.$t("company_name_required");
        } else {
          this.form.companyState = true;
        }
      } else {
        this.form.companyState = null;
      }
    },
    validatePrefix() {
      if(this.form.prefix == undefined){
        this.form.prefixState = null;
      } else {
        this.form.prefixState = true;
        this.form.prefixError = '';
      }
    },
    validateFirstname() {
      if(this.form.firstname == undefined){
        this.form.firstnameState = null;
      }else if (this.form.firstname == "") {
        this.form.firstnameState = false;
        this.form.firstnameError = this.$t("firstname_required");
      } else {
        this.form.firstnameState = true;
      }
    },
    validateLastname() {
      if(this.form.lastname == undefined){
        this.form.lastnameState = null;
      }else if (this.form.lastname == "") {
        this.form.lastnameState = false;
        this.form.lastnameError = this.$t("lastname_required");
      } else {
        this.form.lastnameState = true;
      }
    },
    validateCountry() {
      if (this.form.address.country_code) {
        this.form.address.country_codeState = true;
        this.validateVAT();
      }
      else {
        this.form.address.country_codeState = false;
      }
    },
    // validateHouseNumberAndAddition() {
    //   if (this.form.address.house_number_and_addition == "") {
    //     this.form.address.house_number_and_additionState = false;
    //     this.form.address.house_number_and_additionError = this.$t("house_number_and_addition_required");
    //   }else if (this.foundAddresses.length > 0 && this.selectedAddress == null){
    //     this.form.address.house_number_and_additionState = null;
    //   } else {
    //     this.form.address.house_number_and_additionState = true;
    //     if(config.postcode_validation.countries.includes(this.form.address.country_code)){
    //           this.form.address.house_number_and_additionState = null;
    //     }
    //   }
    // },
    validateHouseNumber() {
      if (this.form.address.house_number == "") {
        this.form.address.house_numberState = false;
        this.form.address.house_numberError = this.$t("house_number_required");
      } else if (this.foundAddresses.length > 0 && this.selectedAddress == null){
        this.form.address.house_numberState = null;
      } else if(this.showFullAddress == false && this.selectedAddress == null  && this.foundAddress == null){
        this.form.address.house_numberState = false;
      } else {
        this.form.address.house_numberState = true;
      }
    },
    validateStreet() {
      if(this.form.address.streetDisplay == undefined){
        this.form.address.streetDisplayState = null;
      }else if (this.form.address.streetDisplay == "") {
        this.form.address.streetDisplayState = false;
        this.form.address.streetDisplayError = this.$t("street_required");
      } else {
        this.form.address.streetDisplayState = true;
      }
    },
    validateCity() {
      if(this.form.address.city == undefined){
        this.form.address.cityState = null;
      }else if (this.form.address.city == "") {
        this.form.address.cityState = false;
        this.form.address.cityError = this.$t("city_required");
      } else {
        this.form.address.cityState = true;
      }
    },
    validatePhone() {
      if(this.form.address.telephone == undefined){
        this.form.address.telephoneState = null;
      }else if (this.form.address.telephone == "") {
        this.form.address.telephoneState = false;
        this.form.address.telephoneError = this.$t("phone_required");
      } else {
        this.form.address.telephoneState = true;
      }
    },
    validatePostCode() {
      const status = this.$helpers.validatePostcode(this.form.address.postcode, this.form.address.country_code);
      this.form.address.postcodeState = status
      
      if(status == false){
        this.form.address.postcodeError = this.$t("postcode_required")
      }
    },
  },
  watch: {
    country() {
      if (config.postcode_validation.enabled) {
        if (!config.postcode_validation.countries.includes(this.country)) {
          this.showFullAddress = true;
        } else {
          this.showFullAddress = false;
        }
      } else {
        this.showFullAddress = true;
      }
      if(this.form.address.postcode != ""){
        this.validatePostCode();
      }
    },
    vatNumber() {
      if (config.vat_validation.enabled) {
        if (this.form.taxvatTimer !== null) {
          clearTimeout(this.form.taxvatTimer);
        }
        this.form.taxvatTimer = setTimeout(
          function (scope) {
            scope.validateVAT();
          },
          1000,
          this
        );
      }
    },
    postcodeHouseNumber(newVal) {
      if (this.showFullAddress == false) {
        if (this.typeTimer !== null) {
          clearTimeout(this.typeTimer);
        }
        if (this.foundAddress != "") {
          this.foundAddress = "";
        }
        // if (this.foundAddresses.length > 0) {
        //   this.foundAddresses = [];
        // }
        const [newPostcode, newHouseNumber] = newVal.split("|");
        if (newPostcode != "" && newHouseNumber != "") {
          this.typeTimer = setTimeout(
            function (scope) {
              scope.validatePostcodeHouseNumber();
            },
            2000,
            this
          );
        }
      }
    },
    selectedAddress(newVal) {
      if (newVal !== null) {
      this.form.address.streetDisplay = this.foundAddresses[newVal].street;
      this.form.address.streetDisplayState = true;
      let addition = "";
      if (this.foundAddresses[newVal].letter != null) {
          addition = this.foundAddresses[newVal].letter;
      }
      if (this.foundAddresses[newVal].addition != null) {
          addition += this.foundAddresses[newVal].letter !== null ? (!isNaN(this.foundAddresses[newVal].letter) && !isNaN(this.foundAddresses[newVal].addition) ? "-" : "") + this.foundAddresses[newVal].addition : this.foundAddresses[newVal].addition;
      }
      this.form.address.house_number = `${this.foundAddresses[newVal].number}`;
      this.form.address.addition = `${addition}`;
      this.form.address.house_numberState = true;
      this.form.address.additionState = true;
      if(this.form.address.addition == '' || this.form.address.addition == null){
        this.form.address.additionState = null;
      }
      this.form.address.city = this.foundAddresses[newVal].city;
      this.form.address.cityState = true;
      this.validatePostCode();
    }
    },
  },
};
</script>

